import clsx from "clsx";
import {formatBranchName} from "../utils";
import React, {FC} from "react";

const ProjectInstanceCard: FC<{
  instance: any
}> = ({ instance }) => {
  const isFree = formatBranchName(instance.branches) === 'master' ||
    formatBranchName(instance.branches) === 'main' ||
    Array.from({length: 20}).map((_, i) => `dev${i + 1}`).includes(formatBranchName(instance.branches));

  return (
    <div className="bg-white overflow-hidden rounded-xl border border-gray-200">
      <div className={clsx("flex items-center gap-x-4 border-b border-gray-900/5 p-6", {
        'bg-green-50': isFree,
        'bg-blue-50': !isFree,
      })}>
        <div className="text-sm font-medium leading-6 text-gray-900 uppercase">{instance.name}</div>
        <a href={instance.url} target="_blank" rel="noopener noreferrer" className="ml-auto rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Go to project
        </a>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Branch</dt>
          <dd className="text-gray-700">
            <div
              className={clsx(
                'rounded-lg px-2 py-1 text-xs font-medium ring-1 ring-inset',
                {
                  'text-green-700 bg-green-50 ring-green-600/20': isFree,
                  'text-blue-700 bg-blue-50 ring-orange-600/10': !isFree
                }
              )}
            >
              {formatBranchName(instance.branches)}
            </div>
          </dd>
        </div>
        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Commit</dt>
          <dd className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900">{instance.subject} ({instance.commit}</div>
          </dd>
        </div>
        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Author</dt>
          <dd className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900">{instance.author}</div>
          </dd>
        </div>
      </dl>
    </div>
  )
}

export default ProjectInstanceCard;