import {formatProjectName} from "../utils";
import React from "react";

const PRELOADER_ITEMS = [
  {
    name: 'kontentino-web-app',
    instances: Array.from({length: 15}),
  },
  {
    name: 'desktopapp',
    instances: Array.from({length: 15}),
  },
  {
    name: 'api',
    instances: Array.from({length: 15}),
  },
  {
    name: 'admin',
    instances: Array.from({length: 15}),
  },
  {
    name: 'marketing-analytics',
    instances: Array.from({length: 15}),
  },
  {
    name: 'urlshortener',
    instances: Array.from({length: 15}),
  },
  {
    name: 'utmtagbuilder',
    instances: Array.from({length: 15}),
  }
]

const ProjectsLoader = () => {
  return (
    <div className="flex flex-col gap-16 mt-8">
      {PRELOADER_ITEMS.map((project, index) => {
        return (
          <div key={index} className="">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="flex items-center justify-between">
                <h2
                  className="text-base font-semibold leading-7 text-gray-900 uppercase">{formatProjectName(project.name)}</h2>
              </div>
              <div className="animate-pulse mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                {project.instances.map((instance: any, index: number) => {
                  return (
                    <div key={index} className="h-[250px] bg-gray-100 overflow-hidden rounded-xl border border-gray-200 animate-pulse" />
                  )
                })}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ProjectsLoader;