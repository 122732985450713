import {FC} from "react";
import { Link } from 'react-router-dom';

const NAVIGATION_ITEMS : {
  name: string;
  href: string;
}[] = [
  { name: 'Projects', href: '/' },
  { name: 'About', href: '/about' },
]

const Navigation: FC = () => {
  return (
    <header className="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10 bg-white">
      <div className="flex items-center container mx-auto w-full">
        <div className="flex flex-1 items-center gap-x-6">
          <img
            alt="Kontentino"
            src="https://www.kontentino.com/kontentino-blue.svg"
            className="h-4 w-auto"
          />
        </div>
        <nav className="hidden md:flex md:gap-x-11 md:text-sm md:font-semibold md:leading-6 md:text-gray-700">
          {NAVIGATION_ITEMS.map((item, itemIdx) => (
            <Link key={itemIdx} to={item.href}>
              {item.name}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  )
}

export default Navigation;