import {FC} from "react";
import clsx from "clsx";

const PROJECTS_CATEGORIES = [
  {
    key: 'all',
    name: 'All',
  },
  {
    key: 'kontentino-web-app',
    name: 'Web App',
  },
  {
    key: 'desktopapp',
    name: 'Desktop App',
  },
  {
    key: 'api',
    name: 'API',
  },
  {
    key: 'admin',
    name: 'Admin',
  },
  {
    key: 'marketing-analytics',
    name: 'Marketing & Analytics',
  },
  {
    key: 'urlshortener',
    name: 'URL Shortener',
  },
  {
    key: 'utmtagbuilder',
    name: 'UTM Tag Builder',
  }
]

const ProjectsFilter: FC<{
  selected: string
  onSelect: (category: string) => void
}> = ({
  selected,
  onSelect
}) => {
  return (
    <header className=" pb-4 pt-6 sm:pb-6">
      <div className=" flex w-full items-center gap-6">
        <h1 className="text-base font-black uppercase leading-7 text-gray-900">Projects</h1>
        <div
          className="order-last border-blue flex w-full gap-x-2 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l overflow-x-auto sm:border-gray-200 sm:pl-6 sm:leading-7">
          {PROJECTS_CATEGORIES.map((item) => (
            <div onClick={() => onSelect(item.key)} key={item.key} className={clsx(
              'cursor-pointer font-medium hover:shadow border px-3 rounded-xl py-1 text-gray-700 whitespace-nowrap bg-white',
              {
                '!border-blue-800 !text-blue-800': selected === item.key,
              }
            )}>
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </header>
  )
}

export default ProjectsFilter;
