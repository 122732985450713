import {FC} from "react";

const Footer: FC = () => {
  return (
    <footer className="bg-white border-t border-t-gray-300">
      <div className="container mx-auto py-12 flex items-center">
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-md leading-5 text-gray-500">
            Created with <span className="text-blue-600">&hearts;</span> by <a href="https://kontentino.com" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Kontentino</a> developers!
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;