import React, { useState } from 'react';
import SEO from '../components/SEO';
import { formatProjectName } from '../utils';
import {useProjects} from "../hooks/useProjects";
import ProjectsFilter from "../components/ProjectsFilter";
import ProjectsLoader from "../components/ProjectsLoader";
import ProjectInstanceCard from "../components/ProjectInstanceCard";

const Home: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('all')
  const { data, isLoading } = useProjects()

  return (
    <SEO title="Projects" description="">
      <div className="container mx-auto">
        <ProjectsFilter
          selected={selectedCategory}
          onSelect={setSelectedCategory}
        />
        {isLoading && <ProjectsLoader />}
        {!isLoading && data && (
          <div className="flex flex-col gap-16 mt-8">
            {data.filter(project => selectedCategory === 'all' ? true : project.name === selectedCategory).map((project, index) => {
              return (
                <div key={index} className="">
                  <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <div className="flex items-center justify-between">
                      <h2 className="text-base font-semibold leading-7 text-gray-900 uppercase">{formatProjectName(project.name)}</h2>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                      {project.instances.map((instance: any, index: number) => {
                        return <ProjectInstanceCard key={index} instance={instance} />
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </SEO>
  );
}

export default Home;
