import Navigation from '../components/Navigation';
import {FC} from "react";
import Footer from "../components/Footer";

const Layout: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div>
      <Navigation />
      <main style={{
        background: 'linear-gradient(111.34deg, rgb(248, 249, 250) 0%, rgb(255, 247, 249) 100%)'
      }}>
        <div className="relative overflow-hidden pt-16 pb-24">
          {children}
          <div
            aria-hidden="true"
            className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
          >
            <div
              style={{
                clipPath:
                  'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
              }}
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
            />
          </div>
        </div>
      </main>
      <Footer/>
    </div>
  );
}

export default Layout;