import {useEffect, useState} from "react";
import Config from "../config";

export const useProjects = () => {
  const [data, setData] = useState<Project[]>([])
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    async function loadProjects() {
      try {
        const response = await fetch(Config.PROJECTS_URL).then(response => response.json());

        if (response.data) {
          setData(response.data.projects);
        }
      } catch (error: any) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    loadProjects();
  }, [])

  return {
    data,
    isLoading,
    isError: !!error,
    error
  }
}